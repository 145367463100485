
h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: orange;
  text-shadow: rgba(81, 52, 21, 0.8) 3px 0px 7px,
    rgba(81, 52, 21, 0.8) -3px 0px 7px, rgba(81, 52, 21, 0.8) 0px 4px 7px;
}

form {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.form-section {
  margin-bottom: 20px;
}

label {
  font-size: 16px;
  margin-bottom: 8px;
  color: rgb(240, 229, 231); /* Dark gray text color */
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

input,
select {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  flex: 4; /* Allow inputs to expand and fill available space */
}

input:focus,
select:focus {
  outline: none;
  border-color: #3498db; /* Highlight border color on focus */
}

/* Add a border to the form sections */
.form-section {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 15px;
}

/* Style the submit button with a transition effect */
button {
  background-color: orange;
  width: 250px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

button:hover {
  background-color: orange;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.font {
  font-size: 38px;
  color: orange; /* Pink color */
  text-shadow: 2px 2px 4px rgba(81, 52, 21, 0.8);
  text-align: center;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-cont {
  display: flex;
  flex-direction: column;
}

input[type="text"] {
  font-size: 16px;
  color: white;
  text-decoration: none;
  margin: 10px;
}

.cont {
  background: rgba(255, 165, 0, 0.2); /* Background color with 80% opacity */
  backdrop-filter: blur(0px); /* Apply a 5px blur effect */
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 15px auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.custom-input {
  /* Add your custom styles here */
  border: 1px solid black;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  transition: border-color 0.3s ease; /* Add a transition for smooth hover effect */
  margin-left: 80px;
  /* Define hover styles */
  &:hover {
    border-color: orange; /* Change border color to black on hover */
    /* Add other styles for the hover effect */
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
}

ul.list-cont {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}

ul.list-cont li {
  flex: 1;
  margin: 10px;
  font-size: 20px;
  font-weight: bold; /* Set font weight to bold */
  text-align: center; /* Center the text horizontally */
  text-transform: uppercase; /* Convert text to uppercase */
}

img {
  max-width: 100%;
  margin-bottom: 20px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.2s, border 0.2s, box-shadow 0.2s,
    background-color 0.2s, opacity 0.2s;
  border: 2px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  opacity: 1;
  transform: scale(1.1); /* Scale the image up by 10% on hover */
}

img:hover {
  transform: scale(1.2); /* Scale the image up on hover */
  border: 2px solid white; /* Change the border color on hover (pink in this case) */
  box-shadow: 0 0 10px white; /* Change the box shadow on hover */
  opacity: 0.8; /* Reduce opacity on hover to 80% */
  cursor: pointer;
}

.react-player video {
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  border: 2px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  opacity: 1;
}

.react-player:hover {
  transform: scale(1.1);
  border: 2px solid white;
  box-shadow: 0 0 10px white;
  opacity: 0.8;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}
.instruction {
  padding: 50px; /* Adjust padding as needed */
  font-size: 28px; /* Adjust font size as needed */
  line-height: 1; /* Adjust line height for better readability */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4); /* Add a shadow for depth */
  margin: 10px 0; /* Add spacing around the instruction box */
  color: rgb(243, 241, 237); /* Set the text color to orange */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Apply a subtle text shadow */
  /* Additional styling properties like text-align and font family can be adjusted to your preference */
}

/*.position-align {
  justify-content: center;
  align-items: center;
}*/
ul.list-cont.position-align {
  margin: 20px;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

/* MuscleComponent.css */
.logout-button {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 16px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  float: right;
  margin-right: 25px;
  transition: background-color 0.3s, transform 0.2s; /* Add a smooth transition effect for background and transform properties */
  width: 100px;
  background: rgba(255, 165, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon and text vertically and horizontally */
}

.logout-button:hover {
  background-color: rgb(240, 177, 60);
  transform: scale(1.1); /* Scale the button on hover for a subtle zoom effect */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a box shadow on hover for depth */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Apply transitions to all properties */
}


/* Styles for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .cont {
    width: 90%; /* Adjust width for smaller screens */
    margin: 10px auto; /* Center the container with reduced margin */
  }

  .custom-input {
    margin-left: 0; /* Remove left margin on smaller screens */
  }
}

/* Additional styles for even smaller screens (e.g., phones) */
@media (max-width: 480px) {
  .cont {
    width: 95%; /* Further adjust width for very small screens */
  }
}

