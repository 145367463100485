.centered-text {
  text-align: center;
/* color: rgb(134, 222, 231);  */
  /* margin-top: 2rem; */
  /* font-size: 1rem; */ /* Adjust font size */
  font-weight: bold; /* Make the text bold */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a shadow effect */
}


/* Add animation to the form when hovered */
.wave-effect-form {
  transition: transform 0.3s ease;
  transform-origin: center bottom;
}
/* Create a pseudo-element for the wave effect */
.wave-effect-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
/* Apply the wave effect on hover */
.wave-effect-form:hover::before {
  opacity: 0.3;
  animation: wave 1s infinite linear;
}
/* Define the wave animation */
@keyframes wave {
  0% {
    transform: scaleY(0.8);
  }
  100% {
    transform: scaleY(1);
  }
}
 .password-strength-container {
  text-align:center;
  font-size:x-large;
  margin-top: 10px;
}
.password-strength {
  display: inline-block;
} 
form {
  width: 100%;
  max-width: 400px; /* Limit form width to 400px */
  margin: 0 auto; /* Center the form horizontally */
  padding: 50px 35px;
  background-color: rgba(216, 245, 245, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}
form input[type="text"] {
  color: white;
}
form * {
  color: black;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
form h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}
.form-group {
  margin-bottom: 15px;
}
label {
  display: block;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}
input[type="email"],
input[type="password"] {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  display: block;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 0 10px;
  margin-top: 0.5rem;
  font-weight: 300;
}
/* Default styles for the button */
/* Your custom CSS styles here */
.signup-button {
  
  background-color: white;
  color: black;


  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  transition: background-color 0.3s, color 0.3s;
  position: relative;
}
/* Wave animation on hover */
.signup-button:hover {
  background-color: black;
  color: white;
  animation: wave 0.5s ease infinite; /* Apply the "wave" animation on hover */
}
@keyframes wave {
  0% {
    transform: scale(1); /* Initial scale (no change) */
  }
  50% {
    transform: scale(1.05); /* Scale up to 105% at 50% of the animation */
  }
  100% {
    transform: scale(
      1
    ); /* Return to the original scale at the end of the animation */
  }
}
/* Add animation to navigation links when clicked */
.navbar a {
  text-decoration: none;
  color: white;
  margin: 0 15px;
  font-weight: bold;
  position: relative; /* Add relative positioning for pseudo-element */
  transition: color 0.3s ease, transform 0.3s ease; /* Add transitions for color and transform */
}
.navbar a::before {
  content: "";
  position: absolute;
  bottom: -3px; /* Adjust the position of the animated line */
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the line height */
  background-color: #d80b0b;
  transform: scaleX(0); /* Initial transform value for the line */
  transform-origin: right;
  transition: transform 0.3s ease; /* Add transition for the line */
}
.navbar a:hover {
  color: #a0042b;
}
.navbar a:hover::before {
  transform: scaleX(1); /* Expand the line when the link is hovered */
  transform-origin: left;
}
@media screen and (max-width: 768px) {
  /* Adjust styles for screens with a maximum width of 768px */
  .registration-container {
    max-width: 100%;
    padding: 10px;
  }
  .centered-text {
    font-size: 0.8rem;
  }
}

