/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #e0a8a8;
}

/* Form styles */
form {
  /*  width: 800px; */
  background-color: rgba(260, 259, 259, 0.075);
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  border: 2px solid rgba(247, 243, 243, 0.1);
  box-shadow: 0 0 40px rgba(13, 15, 26, 0.9);

  /*  padding: 50px 35px; */


}

form h3 {
  /*   font-size: 2rem;
  font-weight: 500;
  line-height: 42px;
  text-align: center; */
}

/* Form elements styles */
.formGroup {
  margin-bottom: 30px;
}

.formedLabel {
  /*  height: 25px; */
  font-weight: bold;
  margin-bottom: 5px;
}

.commonInput {
  width: 100%;
  padding: 10px;
  /*  border: 1px solid #faf8f8; */
  border-radius: 5px;
  font-size: 18px;
}

/* Submit button styles */
button[type="submit"] {
  /* display: inline-block; */
  width: 75%;
  margin-right: 20px; /* Add margin between buttons */
  padding: 10px;

  background-color: rgb(26, 118, 210);
  color: #fff;

  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: rgb(6, 98, 189);
}

/* Label and input styles */
label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"] {
  width: 50%;
  height: 50px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 300;
  
}

/* Sign button styles */
.signButton {
  display: flex; /* Align buttons horizontally */
  justify-content: center;
  gap: 20px; /* Increased margin between buttons */
}

/* Navigation link styles */
/* .navbar a {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
} */

/* .navbar a::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f1e6e6;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
} */
/* 
.navbar a:hover {
  color: #4d2be2;
} */

/* .navbar a:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}
 */
